<template>
  <div>
    <div>
      <span :class="feedbackObject.class">{{feedbackObject.text}}</span>
    </div>
    <div>Total: {{totalSites}}</div>
    <table class="text-left table table-bordered table-responsive-md">
      <thead>
      <tr>
        <th>Site ID</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="blockedSite in blacklistedSites" v-bind:key="blockedSite">
        <td>{{blockedSite}}</td>
        <td>
          <NetworkButton
            method="post"
            :path="`campaigns/${entity.id}/site_update_blacklist`"
            :feedbackObject="feedbackObject"
            :payload="{target_site: blockedSite, block: false}"
            :disabled="false"
            @onSuccess="removed"
          >
            Remove
          </NetworkButton>
        </td>
      </tr>
      </tbody>
    </table>
    <b-pagination ref="pager" pills :limit="13" size="sm" v-model="page" align="center" :total-rows="pTotalSites" :per-page="payload.page_size" first-number last-number :disabled="network.request.isProcessing">
      <template v-slot:ellipsis-text>
        <span v-if="network.request.isProcessing"><b-spinner small></b-spinner></span>
      </template>>
    </b-pagination>
  </div>
</template>

<script>
  import genericRequest from '../components/generic/NetworkRequest.js'

  export default {
    mixins: [genericRequest],

    props: {
      entity: Object,
    },
    data(){
      return {
        page:             1,
        payload:          { page_size: 20 },
        network:          this.createNetworkObject(),
        totalSites:       0,
        pTotalSites:      0,
        feedbackObject:   { class: '', text: '', timestamp: null },
        blacklistedSites: []
      }
    },
    mounted() {
      this.getBlacklist()
    },
    methods: {
      removed(payload){
        this.feedbackObject.text = `${payload.target_site} was removed from the blacklist`;
        let index = this.blacklistedSites.indexOf(payload.target_site);
        this.blacklistedSites.splice(index, 1);
        this.totalSites --;
      },
      getBlacklist(){
        this.request('post', `campaigns/${this.entity.id}/site_blacklist`, this.network, Object.assign({}, this.payload, {page: this.page - 1}))
      }
    },
    watch: {
      page: function() {
        this.getBlacklist();
      },
      'network.response.data': function(data){
        this.totalSites       = data.total;
        this.pTotalSites      = data.total;
        this.blacklistedSites = data.sites
      },
      'network.response.status': function(status){
        this.feedbackObject.class = status
      },
      'network.response.message': function(message){
        this.feedbackObject.text = message
      }
    }
  }
</script>
<style scoped>
  .error {
    color: red
  }
  .success {
    color: green
  }
</style>
