<template>
  <div>
    <FormSubmitter ref="form"
                   :path=           "`campaigns/${campaignId}/site_bid_modifier_update`"
                   :disabled=       "disabled || invalidInput"
                   @onSuccess=      "onSuccess"
                   :feedbackObject= "feedbackObject"
    >Update selected sites
      <span v-if="action !== 'TS'">
        <span>
          <button :disabled="disabled" v-on:click="modify(-1)" class="mod-button px-1">-10%</button>
        </span>
        <span>
          <b-form-input :disabled="disabled" size="sm" name="cpc_value" v-model="input" type="text" class="value" v-bind:class="{ ['validation-error']: invalidInput }" />
        </span>
        <span class="pr-1">
          <button :disabled="disabled" v-on:click="modify(1)" class="mod-button px-1">+10%</button>
        </span>
        <span class="pr-1">
          <b-form-select class="btn-purple" :disabled="disabled" size="sm" name="cpc_unit" v-model="unit">
            <option value="%">%</option>
            <option value="USD" v-if="action.match(/BY|TO/)">USD</option>
          </b-form-select>
        </span>
      </span>
      <span class="pr-1">
          <b-form-select :disabled="disabled" size="sm" name="cpc_action" v-model="action">
            <option value="BY">Of Self</option>
            <option value="TO">Of Base CPC</option>
            <option value="OS">Of Suggested Bid</option>
            <option value="TS">To Suggested Bid</option>
          </b-form-select>
        </span>
      <span v-if="action === 'TS'">{{modalSelectedDate.start.fromNow()}} - {{modalSelectedDate.end.fromNow()}}</span>
      <input name="site_ids" type="hidden" :value="siteIds">
      <input name="suggested_bids" type="hidden" :value="JSON.stringify(suggestedBids)">
    </FormSubmitter>
  </div>
</template>
<script>
  export default {
    props: {
      siteIds:            Array,
      baseBid:            Number,
      campaignId:         Number,
      suggestedBids:      Object,
      feedbackObject:     Object,
      modalSelectedDate:  Object
    },
    data() {
      return {
        step:         null,
        unit:         '%',
        input:        0.0,
        action:       'TS',
        disabled:     true,
        minValues:    null,
        maxValues:    null,
        baseValues:   null,
        invalidInput: true
      }
    },
    mounted() {
      this.baseValues = {
        BY: { ['%']: 10,  USD: this.toNiceNumber(this.baseBid * 0.1) },
        TO: { ['%']: 100, USD: this.toNiceNumber(this.baseBid      ) },
        OS: { ['%']: 10 }
      };
      this.minValues = {
        BY: { ['%']: -99, USD: this.toNiceNumber(this.baseBid * -0.99)  },
        TO: { ['%']: 1,   USD: this.toNiceNumber(this.baseBid *  0.011) },
        OS: { ['%']: -90 }
      };
      this.maxValues = {
        BY: { ['%']: 100, USD: this.toNiceNumber(this.baseBid    ) },
        TO: { ['%']: 200, USD: this.toNiceNumber(this.baseBid * 2) },
        OS: { ['%']: 500 }
      };
      this.validate();
      this.setBase()
    },
    methods: {
      onSuccess() {
        this.feedbackObject.text = `Successfully updated sites: ${this.siteIds.join(', ')}`;
        this.$emit("onSuccess")
      },
      setBase(){
        if (this.action === 'TS') return;

        this.step  = this.unit === 'USD' ? this.toNiceNumber(this.baseBid * 0.1) : 10;
        this.input = this.baseValues[this.action][this.unit]
      },
      modify(sign){
        this.input = this.toNiceNumber(this.input + this.step * sign)
      },
      toNiceNumber(number){
        return parseFloat(parseFloat(number).toFixed(3)) || 0
      },
      validate(){
        if (!this.action || !this.unit) return;
        if (this.action === 'TS') {
          this.invalidInput = false;
        } else {
          let input = this.toNiceNumber(this.input);
          this.invalidInput = input === 0 || (input < this.minValues[this.action][this.unit] || input > this.maxValues[this.action][this.unit]);
        }
        setTimeout(function() {
          this.$refs.form.validateChange() // hidden input do not trigger onchange events so we must manually trigger the form validation for change
        }.bind(this), 100);
      }
    },
    watch: {
      unit: function(){
        if (this.action) this.setBase()
      },
      action: function(){
        if (this.unit) this.setBase()
      },
      siteIds: function(val){
        this.disabled = val.length === 0;
        this.validate()
      },
      input: function(){
        this.validate()
      }
    }
  }
</script>
<style scoped>
  select, input {
    width:    auto;
    display:  inline-block
  }

  .validation-error, .validation-error:focus {
    color:        red;
    font-weight:  bold
  }

  .form-button {
    float: right
  }

  .mod-button {
    position: relative;
    z-index: 2
  }

  .value {
    width:        3.5rem;
    padding-top:  0;
    margin: 0 -0.1rem;
    position: relative;
    z-index: 1
  }

  .btn-purple {
    background: #696FFC !important;
    color: white;
    border-radius: 50px;
    padding: 3px 40px;
    min-width: 130px;
    margin: 0 5px;
    white-space: nowrap;
  }

  .form-button .b-button {
    background: #4BCEAC !important;
    border-color: #4BCEAC !important;
    border-radius: 50px;
    padding: 3px 40px;
    min-width: 130px;
    font-size: 14px;
    margin: 0 5px;
    white-space: nowrap;
  }

  form span:first-child select {
    background: #696FFC !important;
    color: white;
    border-radius: 50px;
    padding: 3px 40px;
    min-width: 130px;
    margin: 0 5px;
    white-space: nowrap;
  }

</style>
